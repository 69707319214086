import React from 'react'
import './styles/globalStyles.css';
const PartnersPortfolio = () => {
  const images = Array.from({ length: 14 }, (_, index) => `rubicon-logos/rubicon-logos-w-${index + 1}.png`);
  return (

<div className="marquee marquee--8 ">
{images.map((src, index) => (
  <img className="marquee__item"  src={src}   key={index} alt={`Image ${index + 1}`}/>
))}
</div>


  )
}

export default PartnersPortfolio